<template>
  <div class="winlose-lists">
    <div>
      <LogTableTransactionsFilter
        :date-start.sync="dateStart"
        :date-end.sync="dateEnd"
        :time-start.sync="timeStart"
        :time-end.sync="timeEnd"
        :search-username.sync="searchUsername"
        :is-search-username="true"
        @onSearch="onSearch"
      />
    </div>
    <div>
      <div class="d-flex justify-content-end align-items-center">
        <div>
          <VueGoodTableSwitchCompactMode />
        </div>
      </div>

      <LogTableTransactions
        ref="logTable"
        :data-lists="items"
        :summary="summary"
        :total-records="totalRecords"
        :is-member="true"
        @onLoadData="onSearch"
      />
    </div>
  </div>
</template>

<script>
import VueGoodTableSwitchCompactMode from '@/components/VueGoodTableSwitchCompactMode'
import LogTableTransactions from '@/components/logs/LogTableTransactions.vue'
import LogTableTransactionsFilter from '@/components/logs/LogTableTransactionsFilter.vue'
import dayjs from '@/libs/dayjs'
import store from '@/store'

export default {
  components: {
    LogTableTransactions,
    LogTableTransactionsFilter,
    VueGoodTableSwitchCompactMode,
  },
  data() {
    return {
      items: [
      ],
      totalRecords: 0,
      summary: {},

      // search
      dateStart: dayjs().format('YYYY-MM-DD'),
      dateEnd: dayjs().add(1, 'days').format('YYYY-MM-DD'),
      timeStart: '00:00',
      timeEnd: '00:00',
      searchUsername: '',

      groupId: '',
    }
  },
  computed: {
    userData() {
      return store.getters['auth/user']
    },
  },
  mounted() {
    this.onSearch()
  },
  beforeDestroy() {
    this.$wait.end('logs-loading')
  },
  methods: {

    onLoadData() {
      this.onSearch()
    },
    async onSearch() {
      this.$wait.start('logs-loading')

      const tableParams = this.$refs.logTable.getTableDataParams()

      try {
        const { data } = await this.$http.get(`/logs/member/transactions`, {
          params: {
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            timeStart: this.timeStart,
            timeEnd: this.timeEnd,
            searchUsername: this.searchUsername,
            ...tableParams,
          },
        })
        this.items = data?.data?.docs ?? []
        this.summary = data?.data?.summary ?? {}
        this.totalRecords = data?.data?.totalDocs ?? 0

        const responseSuccess = data?.success
        const responseMessage = data?.status?.message
        if (!responseSuccess) {
          this.$swal({
            icon: 'error',
            title: this.$t(responseMessage || 'Error'),
          })
          return
        }
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t(error.message || 'Error'),
        })
      } finally {
        this.$wait.end('logs-loading')
      }
    },
  },
}
</script>
